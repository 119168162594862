import React, { FC } from 'react';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Names {
  id: number;
  value: string;
}

interface Props {
  value: string;
  names: Names[];
  onChange?: (event: SelectChangeEvent) => void;
  disabled?: boolean;
  label: string;
}

export const AppSelect: FC<Props> = ({ value, onChange, names, disabled, label }) => {
  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Select
        disabled={disabled}
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        value={value}
        onChange={onChange}
        input={<OutlinedInput sx={{ minWidth: 160 }} label={label} />}
        MenuProps={MenuProps}>
        {names.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
