import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Store } from 'src/types';

import { authReducer } from 'src/modules/auth/reducer';
import { newsReducer } from 'src/modules/news/reducer';
import { settingsReducer } from 'src/modules/settings/reducer';
import { coursesReducer } from 'src/modules/courses/reducer';
import { youtubeReducer } from 'src/modules/youtube/reducer';
import { notifyReducer } from 'src/modules/notify/reducer';
import { usersReducer } from 'src/modules/users/reducer';
import { lifestyleReducer } from '../modules/Lifestyle/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const allReducers = combineReducers<Store>({
  auth: authReducer,
  news: newsReducer,
  settings: settingsReducer,
  courses: coursesReducer,
  youtube: youtubeReducer,
  notify: notifyReducer,
  users: usersReducer,
  lifestyle: lifestyleReducer,
});

const rootReducer = (state: Store | undefined, action: Action): ReturnType<typeof allReducers> => {
  return allReducers(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
