import { createReducer } from 'deox';
import { produce } from 'immer';

import { AuthState } from './types';

import { cleanErrors, getRoles, login, resetPassword } from './actions';

import { resetStore } from 'src/modules/app/actions';

const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  loading: false,
  error: '',
  resetPasswordError: '',
  roles: [],
};

export const authReducer = createReducer(initialState, (handleAction) => [
  handleAction(login.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(login.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.accessToken = payload.idToken;
    }),
  ),
  handleAction(login.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload.error;
    }),
  ),
  handleAction(resetPassword.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(resetPassword.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(resetPassword.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.resetPasswordError = payload.error;
    }),
  ),
  handleAction(cleanErrors, (state) =>
    produce(state, (draft) => {
      draft.error = '';
      draft.resetPasswordError = '';
    }),
  ),

  handleAction(getRoles.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getRoles.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.roles = payload;
    }),
  ),
  handleAction(getRoles.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  handleAction(resetStore, () => ({ ...initialState })),
]);
