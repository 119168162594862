import { ActionType } from 'deox';
import { uploadImage } from './actions';
import { API } from '../../api';
import { put, takeLatest } from 'redux-saga/effects';

function* uploadImageSaga({ payload }: ActionType<typeof uploadImage.request>) {
  const { body, onSuccessCb } = payload;

  try {
    const { data } = yield API.uploadImage(body);

    onSuccessCb(data);

    yield put(uploadImage.success());
    //eslint-disable-next-line
  } catch (e: any) {
    yield put(uploadImage.fail({ error: e.message }));
  }
}

export function* watchSettings() {
  yield takeLatest(uploadImage.request, uploadImageSaga);
}
