import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editLifestyle, getLifestyle } from '../../modules/Lifestyle/actions';
import { EditLifestyleRequestData, Store } from '../../types';
import { LifestyleCard } from './components/LifestyleCard';
import { Grid } from '@mui/material';
import { Modal } from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { LifestyleModalInner } from './components/LifestyleModalInner';
import { Loading } from '../../components/Loading';

export const Lifestyle: FC = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const { appLanguage } = useSelector((state: Store) => state.settings);
  const { lifestyleArea, loading } = useSelector((state: Store) => state.lifestyle);

  const [selectedAreaId, setSelectedAreaId] = useState<null | number>(null);

  const onAreaEitModalOpen = (areaId: number) => {
    setModalOpen(true);
    setSelectedAreaId(areaId);
  };

  const onAreaEditModalClose = () => {
    setModalOpen(false);
  };

  const onEdit = (data: EditLifestyleRequestData) => {
    dispatch(
      editLifestyle.request({
        data,
        onSuccessCb: () => setModalOpen(false),
        successText: t('lifestyleArea.editSuccessText'),
      }),
    );
  };

  useEffect(() => {
    dispatch(getLifestyle.request({ language: appLanguage }));
  }, [appLanguage, dispatch]);

  const selectedArea = useMemo(() => {
    if (selectedAreaId) {
      return lifestyleArea.find((area) => area.id === selectedAreaId) || null;
    }

    return null;
  }, [lifestyleArea, selectedAreaId]);

  if (loading) {
    return <Loading loading={true} />;
  }

  return (
    <Grid container spacing={2} flexDirection={'column'}>
      <Grid item xs={12}>
        {lifestyleArea.map((area) => {
          return <LifestyleCard key={area.id} {...area} onEdit={onAreaEitModalOpen} />;
        })}

        <Modal
          title={t('lifestyleArea.editModalTitle')}
          isOpen={modalOpen}
          handleClose={onAreaEditModalClose}>
          <LifestyleModalInner
            onCloseModal={onAreaEditModalClose}
            onSubmit={onEdit}
            selectedLifestyleArea={selectedArea}
          />
        </Modal>
      </Grid>
    </Grid>
  );
};
