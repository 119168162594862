import React, { FC, memo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import HistoryChange from '../../../../components/HistoryChange/HistoryChange';
import ItemMask from '../../../../components/ItemMask';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { LifestyleT } from '../../../../types';
import { useTranslation } from 'react-i18next';

const styles = {
  title: {
    fontSize: 16,
    fontWeight: 500,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  body: {
    wordWrap: 'break-word',
    fontSize: 14,
    fontWeight: 400,
    lineClamp: 3,
    overflow: 'hidden',
    height: '45px',
    color: 'gray',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
  buttonContainer: {
    height: '50px',
    width: 40,
    display: 'flex',
  },
  button: {
    marginRight: '10px',
  },
};

type Props = LifestyleT & {
  imageUrl?: string | null;
  onEdit: (areaId: number) => void;
  modifiedDate: string | null;
  modifiedByFirstName?: string | null;
  modifiedByLastName?: string | null;
  dateTime?: string;
};

export const LifestyleCard: FC<Props> = memo(function LifestyleCard({
  id,
  onEdit,
  dateTime,
  modifiedDate,
  modifiedByFirstName,
  modifiedByLastName,
  name,
  category,
}) {
  const { formattedDate: formattedDatePublish } = useFormatDate(dateTime);

  const { t } = useTranslation();

  return (
    <Card sx={{ mb: 1 }} style={{ position: 'relative' }}>
      <CardContent
        sx={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hiden',
          minHeight: '120px',
        }}>
        <Box sx={{ width: '80%' }}>
          <Box sx={{ display: 'flex', columnGap: '6px' }}>
            <Typography component="h6" variant="h6" gutterBottom>
              {t('lifestyleArea.category')} -{' '}
              <span style={{ color: '#4caf50' }}>{category.split('_').join(' ')}</span>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', columnGap: '6px' }}>
            <Typography variant="caption" sx={styles.title} component="h2" gutterBottom>
              {t('lifestyleArea.title')} - <span style={{ color: '#4caf50' }}>{name}</span>
            </Typography>
          </Box>
          {dateTime && (
            <Typography component="p" sx={{ fontSize: '14px' }}>
              {formattedDatePublish}
            </Typography>
          )}
        </Box>
        <Box sx={styles.buttonContainer}>
          <IconButton
            sx={styles.button}
            color="primary"
            onClick={() => {
              onEdit(id);
            }}>
            <EditIcon />
          </IconButton>
        </Box>
      </CardContent>
      <Box sx={{ pl: 2 }}>
        <HistoryChange
          modifiedByFirstName={modifiedByFirstName}
          modifiedByLastName={modifiedByLastName}
          modifiedDate={modifiedDate}
        />
      </Box>
      <ItemMask />
    </Card>
  );
});
