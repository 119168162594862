import React, { FC } from 'react';
import { UserCard } from '../UserCard';
import { Store, User } from '../../../../types';
import { Loading } from '../../../../components/Loading';
import { useSelector } from 'react-redux';

type Props = {
  users: User[];
  loading: boolean;
  onDelete: (id: number) => void;
  onEdit: (username: string) => void;
};

export const UsersList: FC<Props> = ({ users, onEdit, onDelete, loading }) => {
  const { searchUsers } = useSelector((state: Store) => state.users);
  const showDeleteButton = !searchUsers.length;

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <div>
      {users?.map((user, index) => {
        return (
          <UserCard
            key={user.id}
            firstName={user.firstName}
            email={user.username}
            lastName={user.lastName}
            role={user.authorities}
            imageUrl={user.imageUrl}
            id={user.id}
            index={index}
            onEdit={onEdit}
            onDelete={onDelete}
            showDeleteButton={showDeleteButton}
          />
        );
      })}
    </div>
  );
};
