import React, { memo } from 'react';
import { Typography, TextField, Grid, Paper, Box } from '@mui/material';

import { CourseT, UploadedImageT } from 'src/types';
import { useTranslation } from 'react-i18next';
import { ImageUploader } from '../../../../components/ImageUploader';

type Props = {
  fieldsValue: CourseT;
  onChange: React.Dispatch<React.SetStateAction<CourseT>>;
  clearError: () => void;
  handleChangeUploadedImage: (image: UploadedImageT | null) => void;
};

export const CourseCard: React.FC<Props> = memo(function CourseCard({
  fieldsValue,
  onChange,
  clearError,
  handleChangeUploadedImage,
}) {
  const { t } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    switch (name) {
      case 'image-url':
        clearError();
        onChange((prevState) => ({
          ...prevState,
          imageUrl: value,
        }));
        break;
      case 'course-url':
        clearError();
        onChange((prevState) => ({
          ...prevState,
          shopLink: value,
        }));
        break;
      case 'course-id':
        onChange((prevState) => ({
          ...prevState,
          id: value,
        }));
        break;
    }
  };

  const { image, shopLink, id } = fieldsValue;

  return (
    <Grid item style={{ position: 'relative' }}>
      <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, mb: 1, mt: 1 }}
          variant="h3"
          component="h3">
          {t('course.courseUrl')}
        </Typography>
        <TextField
          onChange={handleChange}
          value={shopLink}
          name={'course-url'}
          fullWidth
          label={t('course.URL')}
          variant="standard"
        />
        <Typography
          sx={{ fontSize: '16px', fontWeight: 500, mb: 1, mt: 1 }}
          variant="h3"
          component="h3">
          {t('course.courseId')}
        </Typography>
        <TextField
          onChange={handleChange}
          value={id}
          name={'course-id'}
          fullWidth
          label={t('course.ID')}
          variant="standard"
          inputProps={{ maxLength: 20 }}
        />
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }} variant="h3" component="h3">
            {t('course.courseImage')}
          </Typography>
          <ImageUploader image={image} handleChangeUploadedImage={handleChangeUploadedImage} />
        </Box>
      </Paper>
    </Grid>
  );
});
