export const handleLocalStorageValue = ({
  value,
  safe = true,
  parse = true,
}: {
  value: string | null;
  safe?: boolean;
  parse?: boolean;
}) => {
  if (!parse || !value) return value;

  if (!safe) return JSON.parse(value);

  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

export const AppLocalStorage = {
  setItem: (key: string, value: unknown) => {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);

    const event = new StorageEvent('storage', {
      key,
      newValue: stringified,
    });
    window.dispatchEvent(event);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);

    const event = new StorageEvent('storage', {
      key,
      newValue: undefined,
    });
    window.dispatchEvent(event);
  },
  getItem: ({ key, safe, parse }: { key: string; safe?: boolean; parse?: boolean }) => {
    const value = localStorage.getItem(key);

    return handleLocalStorageValue({ value, safe, parse });
  },
};
