import { editLifestyle, getLifestyle } from './actions';
import { API } from 'src/api';
import { put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'deox';
import { Store } from '../../types';
import { showStackBar } from '../settings/actions';

function* getLifestyleAreaSaga({ payload }: ActionType<typeof getLifestyle.request>) {
  try {
    const { data } = yield API.getLifestyleArea(payload);

    yield put(getLifestyle.success(data));
    //eslint-disable-next-line
  } catch (e: any) {
    yield put(getLifestyle.fail({ error: e.message }));
  }
}

function* editLifestyleSaga({ payload }: ActionType<typeof editLifestyle.request>) {
  const { appLanguage } = yield select((state: Store) => state.settings);

  const { data, onSuccessCb, successText } = payload;

  try {
    yield API.editLifestyleArea(data);

    yield put(editLifestyle.success());

    onSuccessCb();

    yield put(
      showStackBar({
        visible: true,
        message: successText,
        variant: 'success',
      }),
    );

    yield put(getLifestyle.request({ language: appLanguage }));
    //eslint-disable-next-line
  } catch (e: any) {
    yield put(editLifestyle.fail({ error: e.message }));
  }
}

export function* watchLifestyle() {
  yield takeLatest(getLifestyle.request, getLifestyleAreaSaga);
  yield takeLatest(editLifestyle.request, editLifestyleSaga);
}
