import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  EditLifestyleRequestData,
  LifestyleT,
  ProductGroup,
  ProductGroupType,
  UploadedImageT,
} from '../../../../types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { ImageUploader } from '../../../../components/ImageUploader';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { AppSelect } from '../../../../components/AppSelect';
import { v4 as uuidv4 } from 'uuid';

const CATEGORIES_TYPES = Object.values(ProductGroupType).map((item, index) => ({
  id: index,
  value: item,
}));

type Props = {
  onCloseModal: () => void;
  onSubmit: (data: EditLifestyleRequestData) => void;
  selectedLifestyleArea: LifestyleT | null;
};

export const LifestyleModalInner: FC<Props> = memo(function LifestyleModalInner({
  onCloseModal,
  onSubmit,
  selectedLifestyleArea,
}) {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [uploadedImage, setUploadedImage] = useState<UploadedImageT | null>(null);
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);

  const handleSubmit = useCallback(() => {
    if (selectedLifestyleArea) {
      onSubmit({
        params: {
          id: selectedLifestyleArea.id,
        },
        body: {
          name: title,
          imageId: uploadedImage?.id || null,
          productGroups: productGroups,
        },
      });
    }
  }, [onSubmit, productGroups, selectedLifestyleArea, title, uploadedImage?.id]);

  const handleChangeUploadedImage = (image: UploadedImageT | null) => {
    setUploadedImage(image);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeGroupNumber = (groupId: string, value: number | string) => {
    setProductGroups((prevState) =>
      prevState.map((group) =>
        group.id === groupId
          ? {
              ...group,
              group: value,
            }
          : group,
      ),
    );
  };

  useEffect(() => {
    if (selectedLifestyleArea) {
      setTitle(selectedLifestyleArea.name);
      setProductGroups(
        selectedLifestyleArea.productGroups.map((group) => ({ ...group, id: uuidv4() })),
      );
      setUploadedImage({
        id: selectedLifestyleArea.imageId,
        url: selectedLifestyleArea.imageUrl,
      });
    }
  }, [selectedLifestyleArea]);

  const isSaveButtonDisabled = useMemo(() => {
    return (
      !uploadedImage ||
      !uploadedImage.id ||
      !uploadedImage.url ||
      !title ||
      productGroups.some((group) => group.group === '' || group.group === '0')
    );
  }, [productGroups, title, uploadedImage]);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <Box sx={{ display: 'flex', columnGap: '6px' }}>
        <Typography component="h6" variant="h6">
          {t('lifestyleArea.category')} -
        </Typography>

        <Typography component="h6" variant="h6" sx={{ color: '#4caf50' }}>
          {selectedLifestyleArea?.category.split('_').join(' ')}
        </Typography>
      </Box>

      <TextField
        value={title}
        fullWidth
        id="standard-multiline-flexible"
        label={t('lifestyleArea.blockTitle')}
        onChange={handleChangeTitle}
        variant="standard"
        placeholder={t('lifestyleArea.enterLifestyleBlockText')}
        inputProps={{ maxLength: 150 }}
      />

      <Box sx={{ mt: 2 }}>
        {productGroups.map((group, index) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }} key={index}>
              <TextField
                type="number"
                value={group.group}
                label={t('lifestyleArea.codeGroup')}
                onChange={(e) => {
                  const { value } = e.target;

                  handleChangeGroupNumber(group.id, value);
                }}
                variant="outlined"
                placeholder={t('lifestyleArea.enterCodeGroup')}
                inputProps={{ maxLength: 150 }}
              />
              <AppSelect
                value={group.type}
                names={CATEGORIES_TYPES}
                label={`${t('lifestyleArea.selectLabel')} ${index + 1}`}
                disabled
              />
            </Box>
          );
        })}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ fontSize: '16px', mb: 1, color: 'rgba(0, 0, 0, 0.6)' }}
          variant="h3"
          component="h3">
          {t('users.image')}
        </Typography>
        <ImageUploader
          image={uploadedImage}
          handleChangeUploadedImage={handleChangeUploadedImage}
        />
      </Box>

      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button disabled={isSaveButtonDisabled} onClick={handleSubmit}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
});
