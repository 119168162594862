import { createAction } from 'deox';
import {
  EditLifestyleRequestData,
  GetLifeStyleAreaPayload,
  GetLifeStyleResponse,
} from '../../types';

export const getLifestyle = {
  request: createAction(
    'lifestyle/GET_LIFESTYLE_REQUEST',
    (resolve) => (payload: GetLifeStyleAreaPayload) => resolve(payload),
  ),
  success: createAction(
    'lifestyle/GET_LIFESTYLE_SUCCESS',
    (resolve) => (payload: GetLifeStyleResponse) => resolve(payload),
  ),
  fail: createAction(
    'lifestyle/GET_LIFESTYLE_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};

export const editLifestyle = {
  request: createAction(
    'lifestyle/EDIT_LIFESTYLE_REQUEST',
    (resolve) =>
      (payload: { data: EditLifestyleRequestData; onSuccessCb: () => void; successText: string }) =>
        resolve(payload),
  ),
  success: createAction('lifestyle/EDIT_LIFESTYLE_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'lifestyle/EDIT_LIFESTYLE_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};
