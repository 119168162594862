import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Grid, Box } from '@mui/material';

import { CourseCard } from './components/CourseCard';
import { Loading } from 'src/components/Loading';
import { LoadingButton } from '@mui/lab';

import { ErrorMessage } from 'src/components/ErrorMessage';

import { getCourses, editCourses } from 'src/modules/courses/actions';

import { CourseT, Store, UploadedImageT } from 'src/types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HistoryChange from '../../components/HistoryChange/HistoryChange';

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const { appLanguage } = useSelector((state: Store) => state.settings);
  const { loading, editLoading, courses, modifiedDate, modifiedByFirstName, modifiedByLastName } =
    useSelector((state: Store) => state.courses);

  const [firstCourse, setFirstCourse] = useState<CourseT>({
    image: null,
    shopLink: '',
    id: '',
  });

  const [secondCourse, setSecondCourse] = useState<CourseT>({
    image: null,
    shopLink: '',
    id: '',
  });

  const [thirdCourse, setThirdCourse] = useState<CourseT>({
    image: null,
    shopLink: '',
    id: '',
  });

  const [fourthCourse, setFourthCourse] = useState<CourseT>({
    image: null,
    shopLink: '',
    id: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourses.request({ language: appLanguage }));
  }, [appLanguage, dispatch]);

  useEffect(() => {
    const courseSetters = [setFirstCourse, setSecondCourse, setThirdCourse, setFourthCourse];

    courses.slice(0, courseSetters.length).forEach((course, index) => {
      const setCourse = courseSetters[index];
      setCourse({
        id: course.id,
        shopLink: course.shopLink,
        image: course.imageUrl
          ? {
              id: Number(course.imageId),
              url: course.imageUrl,
            }
          : null,
      });
    });
  }, [courses]);

  const validateStatus = useMemo(() => {
    const unfilledFields = [firstCourse, secondCourse, thirdCourse, fourthCourse].find((course) => {
      if (course.image === null || course.shopLink === '' || course.id === '') {
        return true;
      }
    });

    if (unfilledFields) {
      return true;
    }

    return false;
  }, [firstCourse, fourthCourse, secondCourse, thirdCourse]);

  const onSubmit = useCallback(() => {
    const coursesData = [firstCourse, secondCourse, thirdCourse, fourthCourse].map((course) => ({
      id: course.id,
      shopLink: course.shopLink,
      imageId: course.image?.id || null,
    }));

    dispatch(
      editCourses.request({
        language: appLanguage,
        courses: coursesData,
      }),
    );
  }, [appLanguage, dispatch, firstCourse, secondCourse, thirdCourse, fourthCourse]);

  const clearError = useCallback(() => {
    setError('');
  }, []);

  const handleChangeUploadedImage = (id: number, image: UploadedImageT | null) => {
    switch (id) {
      case 1:
        setFirstCourse((prevState) => ({ ...prevState, image: image }));
        break;
      case 2:
        setSecondCourse((prevState) => ({ ...prevState, image: image }));
        break;
      case 3:
        setThirdCourse((prevState) => ({ ...prevState, image: image }));
        break;
      case 4:
        setFourthCourse((prevState) => ({ ...prevState, image: image }));
        break;
    }
  };

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container flexDirection="column">
          <CourseCard
            onChange={setFirstCourse}
            fieldsValue={firstCourse}
            clearError={clearError}
            handleChangeUploadedImage={(image) => handleChangeUploadedImage(1, image)}
          />
          <CourseCard
            onChange={setSecondCourse}
            fieldsValue={secondCourse}
            clearError={clearError}
            handleChangeUploadedImage={(image) => handleChangeUploadedImage(2, image)}
          />

          <CourseCard
            onChange={setThirdCourse}
            fieldsValue={thirdCourse}
            clearError={clearError}
            handleChangeUploadedImage={(image) => handleChangeUploadedImage(3, image)}
          />
          <CourseCard
            onChange={setFourthCourse}
            fieldsValue={fourthCourse}
            clearError={clearError}
            handleChangeUploadedImage={(image) => handleChangeUploadedImage(4, image)}
          />
          {error && <ErrorMessage errorMessage={error} />}
        </Grid>
        <Box sx={{ display: 'flex', mt: 1 }} justifyContent="space-between">
          <HistoryChange
            modifiedByFirstName={modifiedByFirstName}
            modifiedByLastName={modifiedByLastName}
            modifiedDate={modifiedDate}
          />
          <LoadingButton
            sx={{ minWidth: '120px', height: '40px' }}
            onClick={onSubmit}
            disabled={validateStatus}
            loading={editLoading}
            variant="contained">
            {t('general.save')}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};
