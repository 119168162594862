import React, { FC } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from '../../modules/settings/actions';
import { Store, UploadedImageT } from '../../types';
import { Loading } from '../Loading';

const fileTypes = ['JPG', 'PNG', 'JPEG', 'WEBP'];

const styles = {
  title: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  box: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
    height: 140,
    border: '2px dashed #1976d2',
    borderRadius: 4,
    position: 'relative',
    cursor: 'pointer',
    padding: '10px',
  } as React.CSSProperties,
  uploadButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  } as React.CSSProperties,
  deleteButton: {
    ml: 1,
    width: 40,
    height: 40,
  } as React.CSSProperties,
  image: { objectFit: 'contain', width: '100%', height: '100%' } as React.CSSProperties,
};

type Props = {
  image: UploadedImageT | null;
  handleChangeUploadedImage: (image: null | UploadedImageT) => void;
};

export const ImageUploader: FC<Props> = ({ image, handleChangeUploadedImage }) => {
  const { t } = useTranslation();
  const { uploadImageLoading } = useSelector((state: Store) => state.settings);

  const dispatch = useDispatch();

  const handleImageUrl = (uploadedImage: UploadedImageT) => {
    handleChangeUploadedImage(uploadedImage);
  };

  const handeChangeImageFile = (file: File) => {
    dispatch(
      uploadImage.request({
        body: {
          file: file,
        },
        onSuccessCb: handleImageUrl,
      }),
    );
  };

  const onDeleteImage = () => {
    handleChangeUploadedImage(null);
  };

  return (
    <Stack>
      <Stack sx={styles.container}>
        <Box sx={styles.box}>
          <FileUploader
            fileOrFiles={null}
            multiple={false}
            onTypeError={(e: any) => alert(e)}
            handleChange={handeChangeImageFile}
            name="file"
            types={fileTypes}>
            <div style={styles.wrapper}>
              {uploadImageLoading ? (
                <Loading size={30} boxHeight={'140px'} loading={uploadImageLoading} />
              ) : (
                <>
                  {!image?.url && (
                    <IconButton sx={styles.uploadButton}>
                      <UploadFileIcon fontSize="large" color="primary" />
                    </IconButton>
                  )}
                  {image?.url && <img src={image.url} style={styles.image} alt="uploaded" />}
                </>
              )}
            </div>
          </FileUploader>
          {!image?.url && <p>{t('users.noImageUploaded')}</p>}
        </Box>

        {image?.url && (
          <IconButton onClick={onDeleteImage} sx={styles.deleteButton}>
            <DeleteIcon color="primary" />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
};
