import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/PrivateRoute';
import { PublicRoute } from 'src/routes/PublicRoute';

import { Login } from 'src/pages/Login';
import { Dashboard } from 'src/pages/Dashboard';

import styles from './App.module.scss';
import { ResetPassword } from '../../pages/ResetPassword';
import { useLocalStorageItem } from '../../hooks/useLocalStoreageItem';
import { ACCESS_TOKEN_NAME } from '../../constants/constants';

export const AppRouter: React.FC = () => {
  const [accessToken] = useLocalStorageItem(ACCESS_TOKEN_NAME);

  const isAuthenticated = !!accessToken;

  return (
    <BrowserRouter>
      <div className={styles.container}>
        <Switch>
          <PublicRoute path="/login" isAuthenticated={isAuthenticated}>
            <Login />
          </PublicRoute>
          <PublicRoute path="/reset-password" isAuthenticated={isAuthenticated}>
            <ResetPassword />
          </PublicRoute>
          <PrivateRoute path="/dashboard" isAuthenticated={isAuthenticated}>
            <Dashboard />
          </PrivateRoute>
          <Redirect exact from="/" to="/dashboard" />
          <Route path="*"></Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
};
