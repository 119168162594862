import { all } from 'redux-saga/effects';

import { watchAuth } from 'src/modules/auth/sagas';
import { watchCourses } from 'src/modules/courses/sagas';
import { watchNews } from 'src/modules/news/sagas';
import { watchYoutube } from 'src/modules/youtube/sagas';
import { watchNotify } from 'src/modules/notify/sagas';
import { watchUsers } from 'src/modules/users/sagas';
import { watchSettings } from '../modules/settings/sagas';
import { watchLifestyle } from '../modules/Lifestyle/sagas';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchNews(),
    watchYoutube(),
    watchCourses(),
    watchNotify(),
    watchUsers(),
    watchSettings(),
    watchLifestyle(),
  ]);
}
