import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box, Paper, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch, useSelector } from 'react-redux';

import { VideoIFrame } from './components/VideoIFrame';
import { Loading } from 'src/components/Loading';
import { ErrorMessage } from 'src/components/ErrorMessage';

import { getYoutubeVideos, editYoutubeVideos } from 'src/modules/youtube/actions';

import { Store } from 'src/types';
import { useTranslation } from 'react-i18next';
import HistoryChange from '../../components/HistoryChange/HistoryChange';

export const Youtube: React.FC = () => {
  const { t } = useTranslation();
  const [firstVideoUrl, setFirstVideoUrl] = useState('');
  const [secondVideoUrl, setSecondVideoUrl] = useState('');
  const [thirdVideoUrl, setThirdVideoUrl] = useState('');
  const [fourthVideoUrl, setFourthVideoUrl] = useState('');

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const {
    loading,
    youtubeVideos,
    editLoading,
    modifiedDate,
    modifiedByFirstName,
    modifiedByLastName,
  } = useSelector((state: Store) => state.youtube);
  const { appLanguage } = useSelector((state: Store) => state.settings);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    if (error) {
      setError('');
    }

    switch (id) {
      case 'video-1':
        setFirstVideoUrl(value);
        break;
      case 'video-2':
        setSecondVideoUrl(value);
        break;
      case 'video-3':
        setThirdVideoUrl(value);
        break;
      case 'video-4':
        setFourthVideoUrl(value);
        break;
    }
  };

  useEffect(() => {
    if (youtubeVideos.length > 0) {
      youtubeVideos.forEach((videoUrl, index) => {
        switch (index) {
          case 0:
            setFirstVideoUrl(videoUrl);
            break;
          case 1:
            setSecondVideoUrl(videoUrl);
            break;
          case 2:
            setThirdVideoUrl(videoUrl);
            break;
          case 3:
            setFourthVideoUrl(videoUrl);
            break;
        }
      });
    }
  }, [youtubeVideos]);

  const handleSubmitVideos = () => {
    const hasEmptyFields = validateFields();

    if (hasEmptyFields) {
      setError('All fields are required');

      return;
    }

    const config = {
      language: appLanguage,
      links: [firstVideoUrl, secondVideoUrl, thirdVideoUrl, fourthVideoUrl],
    };

    dispatch(editYoutubeVideos.request(config));
  };

  const validateFields = () => {
    const hasEmptyFields = [firstVideoUrl, secondVideoUrl, thirdVideoUrl, fourthVideoUrl].some(
      (video) => video.length === 0,
    );

    return hasEmptyFields;
  };

  useEffect(() => {
    dispatch(getYoutubeVideos.request({ language: appLanguage }));
  }, [appLanguage, dispatch]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container flexDirection="column">
          <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}
              variant="h3"
              component="h3">
              {t('youtube.video')} 1
            </Typography>
            <TextField
              onChange={handleChange}
              value={firstVideoUrl}
              id="video-1"
              fullWidth
              label={t('youtube.url')}
              variant="standard"
            />
            {firstVideoUrl && <VideoIFrame videoUrl={firstVideoUrl} />}
          </Paper>
        </Grid>

        <Grid item>
          <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}
              variant="h3"
              component="h3">
              {t('youtube.video')} 2
            </Typography>
            <TextField
              onChange={handleChange}
              value={secondVideoUrl}
              id="video-2"
              fullWidth
              label={t('youtube.url')}
              variant="standard"
            />
            {secondVideoUrl && <VideoIFrame videoUrl={secondVideoUrl} />}
          </Paper>
        </Grid>

        <Grid item>
          <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}
              variant="h3"
              component="h3">
              {t('youtube.video')} 3
            </Typography>
            <TextField
              onChange={handleChange}
              value={thirdVideoUrl}
              id="video-3"
              fullWidth
              label={t('youtube.url')}
              variant="standard"
            />
            {thirdVideoUrl && <VideoIFrame videoUrl={thirdVideoUrl} />}
          </Paper>
        </Grid>

        <Grid item>
          <Paper sx={{ p: 2, minHeight: 200, width: '100%', mt: 1, mb: 1 }}>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, mb: 1 }}
              variant="h3"
              component="h3">
              {t('youtube.video')} 4
            </Typography>

            <TextField
              onChange={handleChange}
              value={fourthVideoUrl}
              id="video-4"
              fullWidth
              label={t('youtube.url')}
              variant="standard"
            />
            {fourthVideoUrl && <VideoIFrame videoUrl={fourthVideoUrl} />}
          </Paper>
        </Grid>

        {error && <ErrorMessage errorMessage={error} />}

        <Box sx={{ display: 'flex', mt: 2 }} justifyContent="space-between">
          <HistoryChange
            modifiedByFirstName={modifiedByFirstName}
            modifiedByLastName={modifiedByLastName}
            modifiedDate={modifiedDate}
          />

          <LoadingButton
            sx={{ minWidth: '120px', height: '40px' }}
            loading={editLoading}
            variant="contained"
            onClick={handleSubmitVideos}>
            {t('general.save')}
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};
