import { createReducer } from 'deox';
import { produce } from 'immer';

import { changeLanguage, showStackBar, uploadImage } from './actions';
import { SettingsState } from './types';
import { LANGUAGES } from 'src/constants/constants';

const savedLang = (localStorage.getItem('lang') || '') as LANGUAGES.EN | LANGUAGES.DE;

const initialState: SettingsState = {
  appLanguage: savedLang || LANGUAGES.EN,
  uploadImageLoading: false,
  stackBar: {
    visible: false,
    message: '',
    variant: 'default',
  },
};

export const settingsReducer = createReducer(initialState, (handleAction) => [
  handleAction(changeLanguage, (state, { payload }) =>
    produce(state, (draft) => {
      draft.appLanguage = payload.language;
    }),
  ),
  handleAction(showStackBar, (state, { payload }) =>
    produce(state, (draft) => {
      draft.stackBar = payload;
    }),
  ),
  handleAction(uploadImage.request, (state) =>
    produce(state, (draft) => {
      draft.uploadImageLoading = true;
    }),
  ),
  handleAction(uploadImage.success, (state) =>
    produce(state, (draft) => {
      draft.uploadImageLoading = false;
    }),
  ),
  handleAction(uploadImage.fail, (state) =>
    produce(state, (draft) => {
      draft.uploadImageLoading = false;
    }),
  ),
]);
