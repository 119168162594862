export type New = {
  body: string;
  id: number;
  imageUrl: string;
  language: 'EN' | 'DE';
  title: string;
  modifiedDate: string | null;
  modifiedByFirstName: string | null;
  modifiedByLastName: string | null;
  imageId: number;
};

export type Notification = {
  content: string;
  shortContent: string;
  id: number;
  imageUrl: string;
  imageId: number;
  publishTime: string;
  title: string;
  isSent: boolean;
  modifiedDate: string | null;
  modifiedByFirstName: string | null;
  modifiedByLastName: string | null;
};

export type Course = {
  id: number | string;
  shopLink: string;
  imageId: number | null;
  imageUrl: string;
};

export type CourseToUpload = {
  id: number | string;
  shopLink: string;
  imageId: number | null;
};

export type Authority = 'ROLE_SUPERADMIN' | 'ROLE_ADMIN';

export type User = {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  language: string;
  imageUrl: string;
  imageId: number;
  authorities: Authority[];
};

export type UploadedImageT = {
  id: number;
  url: string;
};

export type CourseT = {
  id: number | string;
  image: UploadedImageT | null;
  shopLink: string;
};

export enum ProductGroupType {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  STANDALONE = 'STANDALONE',
}

export type ProductGroup = {
  id: string;
  type: ProductGroupType;
  group: number | string;
};

export type LifestyleT = {
  id: number;
  name: string;
  imageId: number;
  imageUrl: string;
  modifiedBy: string;
  modifiedByFirstName: string;
  modifiedByLastName: string;
  modifiedDate: string;
  productGroups: ProductGroup[];
  category: string;
};
