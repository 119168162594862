import { LifestyleState } from './types';
import { createReducer } from 'deox';
import { editLifestyle, getLifestyle } from './actions';
import { produce } from 'immer';

const initialState: LifestyleState = {
  lifestyleArea: [],
  loading: false,
};

export const lifestyleReducer = createReducer(initialState, (handleAction) => [
  handleAction(getLifestyle.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(getLifestyle.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.lifestyleArea = payload;
      draft.loading = false;
    }),
  ),
  handleAction(getLifestyle.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
  //
  handleAction(editLifestyle.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  ),
  handleAction(editLifestyle.fail, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  ),
]);
