import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Store } from '../../types';
import moment from 'moment/moment';
import { LANGUAGES } from '../../constants/constants';

interface HistoryChangeProps {
  modifiedByFirstName?: string | null;
  modifiedByLastName?: string | null;
  modifiedDate: string | null;
}

export const HistoryChange: FC<HistoryChangeProps> = ({
  modifiedByFirstName,
  modifiedByLastName,
  modifiedDate,
}) => {
  const { appLanguage } = useSelector((state: Store) => state.settings);

  if (!modifiedByFirstName && !modifiedByLastName && !modifiedDate) {
    return null;
  }

  const formatDate = (date?: string | null) => {
    const formattedDate =
      appLanguage === LANGUAGES.EN
        ? moment(date).format('DD.MM.YYYY [at] H:mm')
        : moment(date).format('DD.MM.YYYY [um] H:mm');
    return date ? formattedDate : null;
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 3, pb: 2, gap: 2 }}>
      {modifiedByFirstName &&
        (appLanguage === LANGUAGES.EN ? (
          <Typography component="p" sx={{ fontSize: '14px' }}>
            {modifiedByFirstName}, {modifiedByLastName} posted the content on{' '}
            {formatDate(modifiedDate)}.
          </Typography>
        ) : (
          <Typography component="p" sx={{ fontSize: '14px' }}>
            {modifiedByFirstName}, {modifiedByLastName} hat am {formatDate(modifiedDate)} Uhr den
            Content gepostet.
          </Typography>
        ))}
    </Box>
  );
};

export default HistoryChange;
