import React, { memo, useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Loading } from 'src/components/Loading';
import { useSelector } from 'react-redux';
import { EditNewsRequestBody, Store, UploadedImageT } from 'src/types';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { InputLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImageUploader } from '../../../../components/ImageUploader';

type Props = {
  onSubmit: (payload: EditNewsRequestBody) => void;
  onCloseModal: () => void;
};

export const ModalInner: React.FC<Props> = memo(function ModalInner({ onSubmit, onCloseModal }) {
  const { t } = useTranslation();
  const { choosenNews, loadingOneNews } = useSelector((state: Store) => state.news);

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [uploadedImage, setUploadedImage] = useState<UploadedImageT | null>(null);

  const handleChangeUploadedImage = (image: UploadedImageT | null) => {
    setUploadedImage(image);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeBody = (value: string | undefined) => {
    setBody(String(value));
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      body: body ? body : '',
      title: title ? title : '',
      imageId: uploadedImage?.id || null,
      section: 'HES_INTRO',
    });
  }, [body, onSubmit, title, uploadedImage?.id]);

  useEffect(() => {
    if (choosenNews) {
      setTitle(choosenNews.title);
      setBody(choosenNews.body);
      setUploadedImage({
        id: choosenNews.imageId,
        url: choosenNews.imageUrl,
      });
    }
  }, [choosenNews]);

  if (loadingOneNews) {
    return <Loading loading={loadingOneNews} />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <TextField
        value={title}
        fullWidth
        id="standard-multiline-flexible"
        label={t('highEnergySecrets.blockTitle')}
        onChange={handleChangeTitle}
        multiline
        rows={4}
        variant="standard"
        placeholder="Enter a block title"
        inputProps={{ maxLength: 150 }}
      />
      <div data-color-mode="light">
        <InputLabel>{t('highEnergySecrets.blockText')}</InputLabel>
        <MDEditor
          value={body}
          onChange={handleChangeBody}
          textareaProps={{
            placeholder: t('highEnergySecrets.enterIntroductionBlockText'),
            maxLength: 1000,
          }}
          commands={[
            commands.group([commands.title1, commands.title2, commands.title3], {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
            }),
            commands.bold,
            commands.italic,
            commands.link,
            commands.hr,
            commands.quote,
            commands.unorderedListCommand,
            commands.orderedListCommand,
          ]}
          extraCommands={[commands.codeLive, commands.codePreview]}
        />
      </div>

      <Box sx={{ mt: 2 }}>
        <Typography
          sx={{ fontSize: '16px', mb: 1, color: 'rgba(0, 0, 0, 0.6)' }}
          variant="h3"
          component="h3">
          {t('users.image')}
        </Typography>
        <ImageUploader
          image={uploadedImage}
          handleChangeUploadedImage={handleChangeUploadedImage}
        />
      </Box>

      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button disabled={!uploadedImage} onClick={handleSubmit}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
});
