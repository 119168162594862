import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { SnackbarProvider } from 'notistack';
import { Snackbar } from 'src/components/Snackbar';

import { ChangeLanguage } from './components/ChangeLanguage';

import { News } from 'src/pages/News';
import { Courses } from 'src/pages/Courses';
import { Notifications } from 'src/pages/Notifications';

import { ListItems } from './components/ListItems';
import { Youtube } from 'src/pages/Youtube';
import List from '@mui/material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogContentText } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetStore } from 'src/modules/app/actions';
import { PatriciaIntroduction } from 'src/pages/PatriciaIntroduction';
import { HighEnergyIntroduction } from 'src/pages/HighEnergyIntroduction';
import { useTranslation } from 'react-i18next';
import Users from 'src/pages/Users';
import { getRoles } from '../../modules/auth/actions';
import useRole from '../../hooks/useRole';
import { ACCESS_TOKEN_NAME, LANGUAGES } from 'src/constants/constants';
import { Store } from '../../types';
import { AppLocalStorage } from '../../helpers';
import { Lifestyle } from '../Lifestyle';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  appLanguage: string;
}

interface CustomDrawerProps extends MuiDrawerProps {
  appLanguage: string;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, appLanguage }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: appLanguage === LANGUAGES.DE ? 280 : 240,
    width: `calc(100% - ${appLanguage === LANGUAGES.DE ? 280 : 240}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomDrawerProps>(({ theme, open, appLanguage }) => ({
  '& .MuiDrawer-paper': {
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: appLanguage === LANGUAGES.DE ? 280 : 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export const Dashboard = () => {
  const { t } = useTranslation();
  const [selectedBarIndex, setSelectedBarIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const { isSuperAdmin } = useRole();

  const { appLanguage } = useSelector((state: Store) => state.settings);

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };
  const openLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  useEffect(() => {
    dispatch(getRoles.request());
  }, [dispatch]);

  const renderContent = React.useCallback(
    (selectedIndex: number) => {
      switch (selectedIndex) {
        case 0:
          return <News />;
        case 1:
          return <Courses />;
        case 2:
          return <Youtube />;
        case 3:
          return <Notifications />;
        case 4:
          return <PatriciaIntroduction />;
        case 5:
          return <HighEnergyIntroduction />;
        case 6:
          return isSuperAdmin && <Users />;
        case 7:
          return <Lifestyle />;
      }
    },
    [isSuperAdmin],
  );

  const handleLogout = () => {
    closeLogoutDialog();
    dispatch(resetStore());

    AppLocalStorage.removeItem(ACCESS_TOKEN_NAME);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <SnackbarProvider maxSnack={3}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="absolute" open={open} appLanguage={appLanguage}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}>
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                {t('dashboard.dashboard')}
              </Typography>
              <ChangeLanguage />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open} appLanguage={appLanguage}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}>
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <ListItems
              setSelectedIndex={setSelectedBarIndex}
              selectedIndex={selectedBarIndex}
              isSuperAdmin={isSuperAdmin}
            />
            <Divider />
            <List id="logout">
              <ListItem onClick={openLogoutDialog} component={Button}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t('dashboard.logout')} />
              </ListItem>
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
              pl: 7,
            }}>
            <Toolbar />

            <Container maxWidth="xl" sx={{ mt: 5, mb: 4 }}>
              {renderContent(selectedBarIndex)}
            </Container>
          </Box>
        </Box>
        <Dialog open={logoutDialogOpen} onClose={closeLogoutDialog}>
          <DialogTitle id="alert-dialog-title">{t('dashboard.logoutConfirmation')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('dashboard.confirmLogout')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeLogoutDialog}>{t('general.cancel')}</Button>
            <Button onClick={handleLogout} autoFocus>
              {t('dashboard.logout')}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar />
      </SnackbarProvider>
    </ThemeProvider>
  );
};
