import React, { FC } from 'react';
import { Card, CardContent, Stack, SxProps } from '@mui/material';
import { Authority } from '../../../../types';
import { ROLES } from '../../../../constants/constants';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 1,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: '10px',
  },
  leftColumn: {
    flex: '0 0 80px',
  },
  rightColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    flex: 1,
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
    whiteSpace: 'normal',
  } as React.CSSProperties,
  image: {
    width: 170,
    height: 80,
    objectFit: 'contain',
    objectPosition: 'right',
  } as React.CSSProperties,
};

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  role: Authority[];
  id: number;
  imageUrl: string;
  actions?: string;
  index?: number;
  additionalStyles?: SxProps;
  onDelete: (id: number) => void;
  onEdit: (username: string) => void;
  showDeleteButton: boolean;
};

export const UserCard: FC<Props> = ({
  firstName,
  lastName,
  email,
  role,
  id,
  additionalStyles,
  onEdit,
  onDelete,
  showDeleteButton,
  imageUrl,
}) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ mb: 1, ...additionalStyles }} style={{ position: 'relative' }}>
      <CardContent sx={styles.cardContainer}>
        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.firstName')}</div>
          <div style={styles.rightColumn}>{firstName}</div>
        </Stack>

        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.lastName')}</div>
          <div style={styles.rightColumn}>{lastName}</div>
        </Stack>

        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.email')}</div>
          <div style={styles.rightColumn}>{email}</div>
        </Stack>

        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.role')}</div>
          <div style={styles.rightColumn}>{role.map((item) => ROLES[item]).join(', ')}</div>
        </Stack>

        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.image')}</div>
          <div style={styles.rightColumn}>
            {imageUrl ? (
              <img src={imageUrl} style={styles.image} alt="uploaded" />
            ) : (
              'No image uploaded'
            )}
          </div>
        </Stack>

        <Stack sx={styles.row}>
          <div style={styles.leftColumn}>{t('users.actions')}</div>
          <div style={styles.rightColumn}>
            <IconButton
              sx={styles.button}
              color="primary"
              onClick={() => {
                onEdit(email);
              }}>
              <EditIcon />
            </IconButton>

            {showDeleteButton && (
              <IconButton
                color="primary"
                onClick={() => {
                  onDelete(id);
                }}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};
