import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AddButton } from 'src/components/AddButton';
import { Modal } from 'src/components/Modal';
import { Loading } from 'src/components/Loading';
import { NewsItem } from 'src/pages/News/components/NewsItem';

import {
  createNotification,
  getNotifications,
  editNotification,
  deleteNotification,
  selectNotify,
  cleanChosenNotify,
} from 'src/modules/notify/actions';
import { EditNotificationRequestBody, Store } from 'src/types';

import { NotifyInnerModal } from './components/NotifyInnerModal';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const [createNotifyModalVisible, setCreateNotifyModalVisible] = useState(false);
  const [editNotifyModalVisible, setEditNotifyModalVisible] = useState(false);
  const [page, setPage] = useState(0);

  const { appLanguage } = useSelector((state: Store) => state.settings);
  const { loading, totalPages, notifications } = useSelector((state: Store) => state.notify);

  const dispatch = useDispatch();

  const openNotifyModal = useCallback(() => {
    setCreateNotifyModalVisible(true);
  }, []);

  const hideCreateNotifyModal = useCallback(() => {
    setCreateNotifyModalVisible(false);
  }, []);

  const onOpenEditNotifyModal = useCallback(() => {
    setEditNotifyModalVisible(true);
  }, []);

  const onHideEditNotifyModal = useCallback(() => {
    setEditNotifyModalVisible(false);

    dispatch(cleanChosenNotify());
  }, [dispatch]);

  const onCreateNoitfy = useCallback(
    (payload: {
      content: string;
      imageId: number | null;
      publishTime: string;
      title: string;
      shortContent: string;
    }) => {
      dispatch(
        createNotification.request({
          requestBody: {
            ...payload,
            language: appLanguage,
          },
          onSuccessCb: () => {
            setCreateNotifyModalVisible(false);
          },
        }),
      );
    },
    [appLanguage, dispatch],
  );

  useEffect(() => {
    dispatch(
      getNotifications.request({
        language: appLanguage,
        page: page,
        size: 8,
        sort: 'publishTime,desc',
      }),
    );
  }, [appLanguage, dispatch, page]);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    // need subtract 1, because pages received from back is starting from 0
    setPage(value - 1);
  };

  const onEditNotification = useCallback(
    ({ content, imageId, publishTime, title, shortContent }: EditNotificationRequestBody) => {
      dispatch(
        editNotification.request({
          requestBody: {
            content,
            imageId,
            publishTime,
            title,
            shortContent,
          },
          onSuccessCb: () => {
            onHideEditNotifyModal();
          },
        }),
      );
    },
    [dispatch, onHideEditNotifyModal],
  );

  const handleSelectNotify = useCallback(
    (id: number) => {
      const notify = notifications.find((item) => item.id === id);

      if (notify) {
        dispatch(selectNotify(notify));

        onOpenEditNotifyModal();
      }
    },
    [dispatch, notifications, onOpenEditNotifyModal],
  );

  const onDeleteNotify = useCallback(
    (notifyId: number) => {
      dispatch(deleteNotification.request({ notificationId: notifyId }));
    },
    [dispatch],
  );

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {notifications.length > 0 ? (
          notifications.map((item) => (
            <NewsItem
              key={item.id}
              body={item.shortContent || item.content}
              imageUrl={item.imageUrl}
              title={item.title}
              id={item.id}
              dateTime={item.publishTime}
              onClick={handleSelectNotify}
              onDelete={onDeleteNotify}
              modifiedDate={item.modifiedDate}
              modifiedByFirstName={item.modifiedByFirstName}
              modifiedByLastName={item.modifiedByLastName}
            />
          ))
        ) : (
          <Typography sx={{ fontSize: '14px' }} component="h3" variant="h5" color="inherit">
            {t('notifications.seemsLike')}
          </Typography>
        )}
        {notifications.length > 0 && (
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Pagination onChange={handleChangePage} count={totalPages} page={page + 1} />
          </Stack>
        )}
        <AddButton onClick={openNotifyModal} />
        <Modal
          title={t('notifications.createNotification')}
          isOpen={createNotifyModalVisible}
          handleClose={hideCreateNotifyModal}>
          <NotifyInnerModal onSubmit={onCreateNoitfy} onCloseModal={hideCreateNotifyModal} />
        </Modal>
        <Modal
          title={t('notifications.editNotification')}
          isOpen={editNotifyModalVisible}
          handleClose={onHideEditNotifyModal}>
          <NotifyInnerModal onSubmit={onEditNotification} onCloseModal={onHideEditNotifyModal} />
        </Modal>
      </Grid>
    </Grid>
  );
};
