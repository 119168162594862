import { VariantType } from 'notistack';
import { createAction } from 'deox';
import { LANGUAGES } from '../../constants/constants';
import { UploadedImageT, UploadImagePayload } from '../../types';

export const changeLanguage = createAction(
  'settings/CHANGE_LANGUAGE',
  (resolve) => (payload: { language: LANGUAGES.EN | LANGUAGES.DE }) => resolve(payload),
);

export const showStackBar = createAction(
  'settings/SHOW_STACK_BAR',
  (resolve) => (payload: { visible: boolean; message: string; variant?: VariantType }) =>
    resolve(payload),
);

export const uploadImage = {
  request: createAction(
    'settings/UPLOAD_IMAGE_REQUEST',
    (resolve) =>
      (payload: { body: UploadImagePayload; onSuccessCb: (image: UploadedImageT) => void }) =>
        resolve(payload),
  ),
  success: createAction('settings/UPLOAD_IMAGE_SUCCESS', (resolve) => () => resolve()),
  fail: createAction(
    'settings/UPLOAD_IMAGE_FAIL',
    (resolve) => (payload: { error: string }) => resolve(payload),
  ),
};
