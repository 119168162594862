import axios, { AxiosInstance as AxiosInstanceType, AxiosRequestConfig } from 'axios';
import { Store } from 'redux';
import { resetStore } from 'src/modules/app/actions';
import { dispatch } from 'src/store';

import { API_URL } from './constants';
import { AppLocalStorage } from '../helpers';
import { ACCESS_TOKEN_NAME } from '../constants/constants';

type AxiosInstance = {
  instance: AxiosInstanceType | null;
  requestsConfigsQueue: Promise<AxiosRequestConfig>[];
  create: (store: Store) => void;
};

export const axiosInstance: AxiosInstance = {
  instance: null,
  requestsConfigsQueue: [],
  create() {
    this.instance = axios.create({
      baseURL: API_URL,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 401;
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        const accessToken = AppLocalStorage.getItem({ key: ACCESS_TOKEN_NAME });

        if (accessToken) {
          config.headers.Authorization = accessToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(
      async (response) => {
        if (response.status === 401) {
          dispatch(resetStore());
          AppLocalStorage.removeItem(ACCESS_TOKEN_NAME);

          return Promise.reject(response);
        }

        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  },
};
