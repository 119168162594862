export enum LANGUAGES {
  EN = 'EN',
  DE = 'DE',
}

export const ROLES = {
  ROLE_SUPERADMIN: 'Superadmin',
  ROLE_ADMIN: 'Admin',
};

export const ACCESS_TOKEN_NAME = 'accessToken';
