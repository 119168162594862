import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MDEditor, { commands } from '@uiw/react-md-editor';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { ErrorMessage } from 'src/components/ErrorMessage';
import { Loading } from 'src/components/Loading';

import { DateTimePicker } from 'src/pages/Notifications/components/DateTimePicker';

import { Store, UploadedImageT } from 'src/types';
import moment, { Moment } from 'moment';
import { InputLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImageUploader } from '../../../../components/ImageUploader';

type Props = {
  onSubmit: (payload: {
    content: string;
    shortContent: string;
    imageId: number | null;
    publishTime: string;
    title: string;
  }) => void;
  onCloseModal: () => void;
};

export const NotifyInnerModal: React.FC<Props> = ({ onCloseModal, onSubmit }) => {
  const { t } = useTranslation();
  const [content, setContent] = useState('');
  const [shortContent, setShortContent] = useState('');
  const [publishTime, setPublishTime] = useState<Moment | null>(moment());
  const [title, setTitle] = useState('');
  const [error, setError] = useState({
    first: '',
    second: '',
  });
  const [uploadedImage, setUploadedImage] = useState<UploadedImageT | null>(null);
  const { loading, chosenNotify } = useSelector((state: Store) => state.notify);

  const handleChangeUploadedImage = (image: UploadedImageT | null) => {
    setUploadedImage(image);
  };

  const validateFields = () => {
    const isPublishTimeValid = publishTime && publishTime > moment();

    if (!shortContent) {
      setError((prev) => ({
        ...prev,
        first: t('notifications.shotContentIsRequired'),
      }));
      return false;
    }

    if (!content) {
      setError((prev) => ({
        ...prev,
        first: t('notifications.contentIsRequired'),
      }));
      return false;
    }

    if (!isPublishTimeValid && !chosenNotify?.isSent) {
      setError((prev) => ({
        ...prev,
        second: t('notifications.timeMustBeMoreThanCurrent'),
      }));
    }

    if (!isPublishTimeValid && !chosenNotify?.isSent) {
      return false;
    }

    return true;
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeContent = (value: string | undefined) => {
    setContent(String(value));
  };

  const handleChangeShortContent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShortContent(event.target.value);
  };

  const handleSubmit = () => {
    const isFieldsValid = validateFields();

    if (isFieldsValid) {
      onSubmit({
        content,
        shortContent,
        imageId: uploadedImage?.id || null,
        publishTime: publishTime ? publishTime.toISOString() : moment().toISOString(),
        title,
      });
    }
  };

  useEffect(() => {
    if (chosenNotify) {
      setContent(chosenNotify.content);
      setShortContent(chosenNotify.shortContent);
      setTitle(chosenNotify.title);
      setPublishTime(moment(chosenNotify.publishTime));
      setUploadedImage({
        id: chosenNotify.imageId,
        url: chosenNotify.imageUrl,
      });
    }
  }, [chosenNotify]);

  useEffect(() => {
    setError({
      first: '',
      second: '',
    });
  }, [publishTime]);

  const allFieldsFulfilled = useMemo(() => {
    if (content && title) {
      return true;
    }

    return false;
  }, [content, title]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mb: 1, mt: 1 },
        display: 'flex',
        flexDirection: 'column',
      }}
      noValidate
      autoComplete="off">
      <TextField
        value={title}
        fullWidth
        id="standard-multiline-flexible"
        label={t('notifications.title')}
        onChange={handleChangeTitle}
        multiline
        rows={2}
        variant="standard"
        placeholder={t('notifications.enterNotificationTitle')}
        inputProps={{ maxLength: 150 }}
      />
      <TextField
        value={shortContent}
        id="standard-textarea"
        label={t('notifications.shortContent')}
        placeholder={t('notifications.enterNotificationShortContent')}
        onChange={handleChangeShortContent}
        multiline
        rows={4}
        variant="standard"
        inputProps={{ maxLength: 1000 }}
      />
      <div data-color-mode="light">
        <InputLabel>{t('notifications.notificationContent')}</InputLabel>
        <MDEditor
          value={content}
          onChange={handleChangeContent}
          textareaProps={{
            placeholder: t('notifications.enterNotificationContent'),
            maxLength: 1000,
          }}
          commands={[
            commands.group([commands.title1, commands.title2, commands.title3], {
              name: 'title',
              groupName: 'title',
              buttonProps: { 'aria-label': 'Insert title' },
            }),
            commands.bold,
            commands.italic,
            commands.link,
            commands.hr,
            commands.quote,
            commands.unorderedListCommand,
            commands.orderedListCommand,
          ]}
          extraCommands={[commands.codeLive, commands.codePreview]}
        />
      </div>

      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography
          sx={{ fontSize: '16px', mb: 1, color: 'rgba(0, 0, 0, 0.6)' }}
          variant="h3"
          component="h3">
          {t('users.image')}
        </Typography>
        <ImageUploader
          image={uploadedImage}
          handleChangeUploadedImage={handleChangeUploadedImage}
        />
      </Box>

      <DateTimePicker
        setPublishTime={setPublishTime}
        publishTime={publishTime}
        disabled={chosenNotify?.isSent}
      />

      {error.first && <ErrorMessage errorMessage={error.first} />}
      {error.second && <ErrorMessage errorMessage={error.second} />}

      <DialogActions>
        <Button onClick={onCloseModal}>{t('general.cancel')}</Button>
        <Button disabled={!allFieldsFulfilled} onClick={handleSubmit}>
          {t('general.save')}
        </Button>
      </DialogActions>
    </Box>
  );
};
